@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    $foreground: map.get($theme, foreground);

    .material-red-checkbox {
      .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: mat.m2-get-color-from-palette($foreground, text);
        --mdc-checkbox-selected-focus-icon-color: #ff4d4d;
        --mdc-checkbox-selected-hover-icon-color: #ff4d4d;
        --mdc-checkbox-selected-icon-color: #ff4d4d;
        --mdc-checkbox-selected-pressed-icon-color: #ff4d4d;
      }
    }

    .material-orange-checkbox {
      .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: mat.m2-get-color-from-palette($foreground, text);
        --mdc-checkbox-selected-focus-icon-color: #fcbe00;
        --mdc-checkbox-selected-hover-icon-color: #fcbe00;
        --mdc-checkbox-selected-icon-color: #fcbe00;
        --mdc-checkbox-selected-pressed-icon-color: #fcbe00;
      }
    }

    .material-yellow-checkbox {
      .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: mat.m2-get-color-from-palette($foreground, text);
        --mdc-checkbox-selected-focus-icon-color: yellow;
        --mdc-checkbox-selected-hover-icon-color: yellow;
        --mdc-checkbox-selected-icon-color: yellow;
        --mdc-checkbox-selected-pressed-icon-color: yellow;
      }
    }

    .material-green-checkbox {
      .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: mat.m2-get-color-from-palette($foreground, text);
        --mdc-checkbox-selected-focus-icon-color: #00cc00;
        --mdc-checkbox-selected-hover-icon-color: #00cc00;
        --mdc-checkbox-selected-icon-color: #00cc00;
        --mdc-checkbox-selected-pressed-icon-color: #00cc00;
      }
    }

    .material-grey-checkbox {
      .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: mat.m2-get-color-from-palette($foreground, text);
        --mdc-checkbox-selected-focus-icon-color: #666;
        --mdc-checkbox-selected-hover-icon-color: #666;
        --mdc-checkbox-selected-icon-color: #666;
        --mdc-checkbox-selected-pressed-icon-color: #666;
      }
    }
  }
}
