@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    sds-analysis-fleet-plant-filter {
      mat-tab-header {
        background-color: map.get($theme, card);
      }
    }
  }
}
