@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    mat-list-item {
      --mat-list-active-indicator-color: pink;
      --mat-list-list-item-leading-icon-start-space: 12px;
      --mat-list-list-item-leading-icon-end-space: 12px;
      --mdc-list-list-item-leading-icon-color: black;
      --mdc-list-list-item-label-text-color: black;
      --mdc-list-list-item-hover-leading-icon-color: grey;
      --mdc-list-list-item-hover-label-text-color: grey;
    }
  } @else {
    mat-list-item {
      --mdc-list-list-item-leading-icon-color: var(--mat-icon-color);
    }
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $isDarkTheme: map.get($theme, is-dark);

    .mat-mdc-list-item {
      &.active {
        color: mat.m2-get-color-from-palette($primary-palette) !important;
        background: map.get($theme, router-link-active) !important;
      }

      &:hover {
        background: map.get($theme, hover) !important; // todo 5%
      }
      &.selected {
        background: map.get($theme, light-background) !important; //todo 2%
      }
    }
  }
}
