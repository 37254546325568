@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $primary-contrast: mat.m2-get-contrast-color-from-palette($primary-palette, 500);
    $accent-color: mat.m2-get-color-from-palette($accent-palette);
    $accent-contrast: mat.m2-get-contrast-color-from-palette($accent-palette, 500);

    .mat-mdc-chip.mat-mdc-standard-chip {
      --mdc-chip-disabled-label-text-color: #{map.get($theme, 'disabled-text')};
      --mdc-chip-with-icon-disabled-icon-color: #{map.get($theme, 'disabled-text')};
      --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{map.get($theme, 'disabled-text')};
      --mdc-chip-flat-unselected-outline-width: 0;
      --mdc-chip-flat-outline-width: 0;
      --mdc-chip-outline-width: 0;
      &.mat-primary {
        --mdc-chip-elevated-container-color: #{$primary-color};
        --mdc-chip-label-text-color: #{$primary-contrast};
        --mdc-chip-with-icon-icon-color: #{$primary-contrast};
        --mdc-chip-with-trailing-icon-trailing-icon-color: #{$primary-contrast};
        --mdc-chip-with-icon-selected-icon-color: #{$primary-contrast};
      }

      &.mat-accent {
        --mdc-chip-elevated-container-color: #{$accent-color};
        --mdc-chip-label-text-color: #{$accent-contrast};
        --mdc-chip-with-icon-icon-color: #{$accent-contrast};
        --mdc-chip-with-trailing-icon-trailing-icon-color: #{$accent-contrast};
        --mdc-chip-with-icon-selected-icon-color: #{$accent-contrast};
      }
    }
  }
}
