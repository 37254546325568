ag-grid-angular {
  width: 100%;
  height: 100%;
}

// ag grid now has some helper text for filters and sorting, it's misleading and needs to be hidden
.ag-aria-description-container,
.ag-sort-order {
  display: none !important;
}

.ag-theme-material {
  // properties above are possible to override and their list and default values can be found in  @ag-grid-community\core\dist\styles\ag-theme-material\sass\_ag-theme-material-default-params.scss
  // if what you are looking for is not there, it needs to be changed by css styles below.
  $backgroundColors: (
    0: #f28f43,
    1: #29f235,
    2: #ffff00,
    3: #fe2622,
    4: #2f7ed8,
    5: #0d233a,
    6: #8bbc21,
    7: #910000,
    8: #1aadce,
    9: #492970,
    10: #898989,
    11: #77a1e5,
    12: #c42525,
    13: #a6c96a
  );

  @each $id, $color in $backgroundColors {
    .row-background-color-#{$id} {
      background-color: $color;
    }
  }

  .ag-checkbox {
    margin-right: 10px;
  }

  .ag-icon,
  .ag-header-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding-right: 15px;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    text-transform: uppercase;
    padding: 0px;
    background-color: transparent !important;
  }

  .compact-cell {
    text-align: center;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .ag-overlay-loading-wrapper {
    background-color: transparent;
  }

  .ag-body-viewport {
    overflow-y: scroll;
  }

  .ag-body .ag-pinned-left-cols-viewport,
  .ag-body .ag-body-viewport,
  .ag-body .ag-pinned-right-cols-viewport {
    scrollbar-width: thin;
  }

  .cell-align-left {
    justify-content: flex-start; //is in flexbox
  }

  .cell-align-center {
    text-align: center;
    justify-content: center; //is in flexbox
  }

  .cell-align-right {
    justify-content: flex-end; //is in flexbox
    padding-right: 20px !important; // otherwise overwritten by .ag-cell
  }

  .header-align-right {
    .ag-header-cell-label {
      justify-content: flex-end; //is in flexbox
      padding-right: 20px;
    }
  }

  .header-align-center {
    .ag-header-cell-label {
      justify-content: center; //is in flexbox
    }
  }

  .header-align-left {
    .ag-cell-label-container {
      justify-content: start;
    }
    .ag-header-cell-label {
      justify-content: start; //is in flexbox
      flex: none;
    }
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  .ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .ag-row .ag-cell {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .visible-on-row-hover {
    display: none;
  }

  .ag-header-cell {
    &.ellipsis {
      display: inline-flex !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline !important;
  }

  .ag-body-horizontal-scroll-viewport {
    overflow-x: auto !important;
  }

  .disable-select {
    pointer-events: none;
    opacity: 0.4;
  }
}
