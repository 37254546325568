@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    $foreground: map.get($theme, foreground);

    $textColor: mat.m2-get-color-from-palette($foreground, text);
    $borderColor: map.get($theme, border);
    $borderRadius: map.get($theme, border-radius);

    .ngx-file-drop__drop-zone {
      // 99% to allow for scrolling inside the dialog content without the scrollbars visible all the time
      height: 98% !important;
      width: 98%;
      border: 2px dashed $borderColor !important;
      border-radius: $borderRadius !important;
    }
    .ngx-file-drop__content {
      height: 100% !important;
      width: 100%;
      color: $textColor !important;
    }
  }
}
