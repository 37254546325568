@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    mat-sidenav {
      --mat-sidenav-container-width: auto;
      --mat-sidenav-container-background-color: black;
      --mat-sidenav-container-text-color: white;

      mat-list-item {
        --mat-list-active-indicator-color: pink; //todo: not working
        --mat-list-list-item-leading-icon-start-space: 12px;
        --mat-list-list-item-leading-icon-end-space: 12px;
        --mdc-list-list-item-leading-icon-color: white;
        --mdc-list-list-item-label-text-color: white;
        --mdc-list-list-item-hover-leading-icon-color: grey;
        --mdc-list-list-item-hover-label-text-color: grey;
      }

      mat-expansion-panel {
        --mat-expansion-container-background-color: black;
        --mat-expansion-container-text-color: white;
        --mat-expansion-header-text-color: white;
        --mat-icon-color: white;
      }
    }
    & {
      --mat-sidenav-content-background-color: #f1f5f9;
    }
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $accent-color: mat.m2-get-color-from-palette($accent-palette);

    .mat-sidenav {
      background-color: map.get(map.get($theme, sidenav), surface);

      .mat-expansion-panel {
        background: none !important;
      }

      .mat-expansion-panel-header {
        font-size: 1rem !important;
      }

      .mat-expansion-panel-header-description,
      .mat-expansion-indicator:after {
        color: map.get(map.get($theme, sidenav), text) !important;
        border-color: map.get(map.get($theme, sidenav), text) !important;
      }
    }

    .subRouteActive {
      color: mat.m2-get-color-from-palette($primary-palette, 'darker');
    }

    .mat-nav-list {
      .mat-expansion-panel-header:hover {
        background: map.get($theme, hover);
      }
    }

    .mat-drawer:not(.mat-drawer-side) {
      // copy of mat-elevation-z2 shadow
      box-shadow:
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }
}
