@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    $foreground: map.get($theme, foreground);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $accent-color: mat.m2-get-color-from-palette($accent-palette);
    $border-color: map.get($theme, border);

    $textColor: mat.m2-get-color-from-palette($foreground, text);
    $labelBox: map.get($theme, tooltip);
    $tooltipBackground: map.get($theme, toolbar);
    $labelText: map.get($theme, tooltip);
    $transparentBackground: transparent;
    $typography-config: mat.m2-get-typography-config($theme);
    $fontFamily: mat.m2-font-family($typography-config);
    $gridLineColor: map.get($theme, chart-line);

    $specialColors: (
      'invisible': transparent
    );

    .primary-color.highcharts-series {
      color: $primary-color !important;
      fill: $primary-color !important;
      stroke: $primary-color !important;
    }

    .highcharts-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      text-align: left;
      line-height: normal;
      z-index: 0;
      /* TODO  */
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      font-family: $fontFamily;

      // font-weight: 100 !important;
      // font-size: 1rem;
      user-select: none;
    }

    .highcharts-root {
      display: block;
    }

    .highcharts-root text {
      stroke-width: 0;
    }

    .highcharts-strong {
      font-weight: bold;
    }

    .highcharts-emphasized {
      font-style: italic;
    }

    .highcharts-anchor {
      cursor: pointer;
    }

    .highcharts-plot-border,
    .highcharts-plot-background {
      fill: none;
    }

    .highcharts-label-box {
      &.highcharts-no-data-box {
        fill: none;
      }
    }

    .highcharts-button-box {
      fill: inherit;
    }

    .highcharts-tracker-line {
      stroke-linejoin: round;
      stroke: rgba(192, 192, 192, 0.0001);
      stroke-width: 22;
      fill: none;
    }

    /* Titels */
    .highcharts-title {
      @extend .titleStyle;
      fill: $primary-color !important;
      white-space: nowrap;
    }

    .highcharts-subtitle {
      color: $accent-color !important;
      fill: $accent-color !important;
      font-size: 0.8rem;
    }

    .highcharts-caption {
      color: $primary-color !important;
      fill: $primary-color !important;
      font-size: 0.6rem;
    }

    /* Axes */
    .highcharts-axis-line {
      fill: none;
      stroke: #ccd6eb;
    }

    .highcharts-yaxis .highcharts-axis-line {
      stroke-width: 0px;
    }

    .highcharts-axis-title {
      fill: $textColor;
    }

    .highcharts-axis-labels {
      fill: $textColor;
      color: $textColor;
      cursor: default;
      font-size: 12px !important;
    }

    .highcharts-axis-labels span {
      font-size: 12px !important;
      color: $textColor !important;
      font-family: $fontFamily !important;
    }

    .highcharts-axis-labels tspan {
      font-size: 12px !important;
    }

    .highcharts-axis-labels text {
      font-size: 12px !important;
    }

    .highcharts-grid-line {
      fill: none;
      stroke: $gridLineColor;
      stroke-width: 1px;
    }

    .highcharts-yaxis-grid .highcharts-grid-line {
      stroke-width: 0.5px;
    }

    .highcharts-grid-line-invisible {
      .highcharts-grid-line {
        stroke-width: 0 !important;
      }
    }

    .highcharts-xaxis-grid .highcharts-grid-line {
      stroke-width: 0px;
    }

    .highcharts-tick {
      stroke: $textColor;
    }

    .highcharts-yaxis .highcharts-tick {
      stroke-width: 0;
    }

    .highcharts-minor-grid-line {
      stroke: #f2f2f2;
    }

    .highcharts-crosshair-thin {
      stroke-width: 1px;
      stroke: #cccccc;
    }

    .highcharts-crosshair-category {
      stroke: #ccd6eb;
      stroke-opacity: 0.25;
    }

    /* Credits */
    .highcharts-credits {
      cursor: pointer;
      fill: #999999;
      font-size: 0.7em;
      transition:
        fill 250ms,
        font-size 250ms;
    }

    .highcharts-credits:hover {
      fill: black;
      font-size: 14px;
    }

    /* Tooltip */
    .highcharts-tooltip {
      cursor: default;
      pointer-events: none;
      white-space: normal;
      transition: stroke 150ms;
      background: $transparentBackground;
    }

    .highcharts-tooltip span {
      background: $transparentBackground;
      fill: $labelText;
      color: $labelText;
      font-family: $fontFamily !important;
    }

    .highcharts-tooltip table {
      background: $tooltipBackground;
      border: 1px solid $border-color;
      color: $labelText;
      fill: $labelText;
    }

    .floating-tooltip {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    .highcharts-tooltip .highcharts-header {
      color: $primary-color;
      fill: $primary-color;
      font-size: 0.85em;
    }

    .highcharts-tooltip-box {
      stroke-width: 0px;
      fill: $transparentBackground;
      fill-opacity: 1;
      z-index: 999 !important;
    }

    .highcharts-tooltip-box .highcharts-label-box {
      fill: $transparentBackground;
      fill-opacity: 1;
    }
    .tooltip-with-border {
      fill: $tooltipBackground !important;

      .tooltip-with-border-box {
        stroke-width: 1px;
      }
    }

    .highcharts-label {
      color: $labelText;
      fill: $labelText;
    }

    div.highcharts-tooltip {
      filter: none;
    }

    .highcharts-selection-marker {
      fill: #335cad;
      fill-opacity: 0.25;
    }

    .highcharts-graph {
      fill: none;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .highcharts-empty-series {
      stroke-width: 1px;
      fill: none;
      stroke: #cccccc;
    }

    .highcharts-state-hover .highcharts-graph {
      stroke-width: 3;
    }

    .highcharts-point-inactive {
      opacity: 0.2;
      transition: opacity 50ms;
      /* quick in */
    }
    .highcharts-series-inactive {
      opacity: 0.3;
      transition: opacity 100ms;
      /* quick in */
    }

    .highcharts-state-hover path {
      transition: stroke-width 50ms;
      /* quick in */
    }

    .highcharts-state-normal path {
      transition: stroke-width 250ms;
      /* slow out */
    }

    /* Legend hover affects points and series */
    // g.highcharts-series,
    // .highcharts-point,
    // .highcharts-markers,
    // .highcharts-data-labels {
    //   transition: opacity 250ms;
    // }

    // .highcharts-legend-series-active
    // g.highcharts-series:not(.highcharts-series-hover),
    // .highcharts-legend-point-active
    // .highcharts-point:not(.highcharts-point-hover),
    // .highcharts-legend-series-active
    // .highcharts-markers:not(.highcharts-series-hover),
    // .highcharts-legend-series-active
    // .highcharts-data-labels:not(.highcharts-series-hover) {
    //   opacity: 0.2;
    // }

    .highcharts-dashed {
      stroke-dasharray: 1, 5;
    }

    //Special definition for series with colorIndex -1 to have no effect, do not set any color
    .highcharts-color--1 {
      text-align: inherit;
    }

    .highcharts-area {
      fill-opacity: 0.75;
      stroke-width: 0;
    }

    .highcharts-markers {
      stroke-width: 1px;
      stroke: #ffffff;
    }

    .highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
    .highcharts-a11y-marker-hidden {
      opacity: 0;
    }

    .highcharts-point {
      stroke-width: 1px;
    }

    .highcharts-dense-data .highcharts-point {
      stroke-width: 0;
    }

    .highcharts-data-label {
      font-size: 14px;
      font-weight: bold;
    }

    .highcharts-data-label-box {
      fill: none !important;
      stroke-width: 0;
    }

    .highcharts-data-label text,
    text.highcharts-data-label {
      fill: $primary-color;
    }

    .highcharts-data-label-connector {
      fill: none;
    }

    .highcharts-data-label-hidden {
      pointer-events: none;
    }

    .highcharts-color-invisible {
      .highcharts-halo {
        fill-opacity: 0 !important;
        stroke-width: 0;
      }
    }
    .highcharts-halo {
      fill-opacity: 0.25;
      stroke-width: 0;
    }

    .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
    .highcharts-markers .highcharts-point-select {
      fill: #cccccc;
      stroke: #000000;
    }

    .highcharts-column-series .highcharts-point {
      transition: fill-opacity 250ms;
    }

    .highcharts-column-series .highcharts-point-hover {
      fill-opacity: 0.75;
      transition: fill-opacity 50ms;
    }

    .highcharts-pie-series .highcharts-point {
      stroke-linejoin: round;
      stroke: #ffffff;
    }

    .highcharts-pie-series .highcharts-point-hover {
      fill-opacity: 0.75;
      transition: fill-opacity 50ms;
    }

    .highcharts-funnel-series .highcharts-point {
      stroke-linejoin: round;
      stroke: #ffffff;
    }

    .highcharts-funnel-series .highcharts-point-hover {
      fill-opacity: 0.75;
      transition: fill-opacity 50ms;
    }

    .highcharts-funnel-series .highcharts-point-select {
      fill: inherit;
      stroke: inherit;
    }

    .highcharts-pyramid-series .highcharts-point {
      stroke-linejoin: round;
      stroke: #ffffff;
    }

    .highcharts-pyramid-series .highcharts-point-hover {
      fill-opacity: 0.75;
      transition: fill-opacity 50ms;
    }

    .highcharts-pyramid-series .highcharts-point-select {
      fill: inherit;
      stroke: inherit;
    }

    .highcharts-solidgauge-series .highcharts-point {
      stroke-width: 0;
    }

    .highcharts-treemap-series .highcharts-point {
      stroke-width: 1px;
      stroke: #e6e6e6;
      transition:
        stroke 250ms,
        fill 250ms,
        fill-opacity 250ms;
    }

    .highcharts-treemap-series .highcharts-point-hover {
      stroke: #999999;
      transition:
        stroke 25ms,
        fill 25ms,
        fill-opacity 25ms;
    }

    .highcharts-treemap-series .highcharts-above-level {
      display: none;
    }

    .highcharts-treemap-series .highcharts-internal-node {
      fill: none;
    }

    .highcharts-treemap-series .highcharts-internal-node-interactive {
      fill-opacity: 0.15;
      cursor: pointer;
    }

    .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
      fill-opacity: 0.75;
    }

    .highcharts-vector-series .highcharts-point {
      fill: none;
      stroke-width: 2px;
    }

    .highcharts-windbarb-series .highcharts-point {
      fill: none;
      stroke-width: 2px;
    }

    .highcharts-lollipop-stem {
      stroke: #000000;
    }

    .highcharts-focus-border {
      fill: none;
      stroke-width: 2px;
    }

    .highcharts-legend-item-hidden .highcharts-focus-border {
      fill: none !important;
    }

    /* Legend */
    .highcharts-legend-box {
      fill: none;
      stroke-width: 0;
    }

    .highcharts-legend-item {
      span {
        top: 0 !important;
      }
    }

    .highcharts-legend-item > text {
      fill: $primary-color;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      stroke-width: 0;
    }

    .highcharts-legend-item:hover text {
      fill: $primary-color;
    }

    .highcharts-legend-item-hidden * {
      fill: #cccccc !important;
      stroke: #cccccc !important;
      transition: fill 250ms;
    }

    .highcharts-legend-nav-active {
      fill: #003399;
      cursor: pointer;
    }

    .highcharts-legend-nav-inactive {
      fill: #cccccc;
    }

    circle.highcharts-legend-nav-active,
    circle.highcharts-legend-nav-inactive {
      /* tracker */
      fill: rgba(192, 192, 192, 0.0001);
    }

    .highcharts-legend-title span {
      width: 180px;
      white-space: normal !important;
      top: auto !important;
      bottom: 20px;
      text-transform: capitalize;
    }

    .highcharts-legend-title-box {
      fill: none;
      stroke-width: 0;
    }

    /* Bubble legend */
    .highcharts-bubble-legend-symbol {
      stroke-width: 2;
      fill-opacity: 0.5;
    }

    .highcharts-bubble-legend-connectors {
      stroke-width: 1;
    }

    .highcharts-bubble-legend-labels {
      fill: #333333;
    }

    /* Loading */
    .highcharts-loading {
      position: absolute;
      background-color: #ffffff;
      opacity: 0.5;
      text-align: center;
      z-index: 10;
      transition: opacity 250ms;
    }

    .highcharts-loading-hidden {
      height: 0 !important;
      opacity: 0;
      overflow: hidden;
      transition:
        opacity 250ms,
        height 250ms step-end;
    }

    .highcharts-loading-inner {
      font-weight: bold;
      position: relative;
      top: 45%;
    }

    .highcharts-plot-band {
      &.plot-band-highlight-error-timeframe {
        fill: rgba(249, 79, 89, 0.75);
        fill-opacity: 1;
      }

      &.plot-band-highlight-monitoring-timeframe {
        fill: rgba(map.get($theme, light-background), 0.4);
        fill-opacity: 1;
      }

      &.plot-band-highlight-analysis-pm-reg {
        fill: rgba(47, 89, 226);
        fill-opacity: 0.2;
        stroke: rgba(47, 89, 226);
      }
    }
    .highcharts-plot-band-label {
      fill: $textColor;
      stroke: $textColor;

      .hide-in-plot-label {
        display: none;
      }

      .show-in-plot-label {
        display: inline !important;
      }
    }
    .highcharts-plot-line {
      stroke: #999999;
      stroke-width: 1px;
    }

    .highcharts-plot-line-0 {
      stroke-width: 2px !important;
    }

    .highcharts-boxplot-box {
      fill: #ffffff;
    }

    .highcharts-boxplot-median {
      stroke-width: 2px;
    }

    .highcharts-bubble-series .highcharts-point {
      fill-opacity: 0.5;
    }

    .highcharts-errorbar-series .highcharts-point {
      stroke: #000000;
    }

    .highcharts-gauge-series .highcharts-data-label-box {
      stroke: #cccccc;
      stroke-width: 1px;
    }

    .highcharts-gauge-series .highcharts-dial {
      fill: #000000;
      stroke-width: 0;
    }

    .highcharts-polygon-series .highcharts-graph {
      fill: inherit;
      stroke-width: 0;
    }

    .highcharts-waterfall-series .highcharts-graph {
      /* Highcharts More and modules */
      stroke: #333333;
      stroke-dasharray: 1, 3;
    }

    .highcharts-sankey-series .highcharts-point {
      stroke-width: 0;
    }

    .highcharts-sankey-series .highcharts-link {
      transition:
        fill 250ms,
        fill-opacity 250ms;
      fill-opacity: 0.5;
    }

    .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
      transition:
        fill 50ms,
        fill-opacity 50ms;
      fill-opacity: 1;
    }

    .highcharts-venn-series .highcharts-point {
      fill-opacity: 0.75;
      stroke: #cccccc;
      transition:
        stroke 250ms,
        fill-opacity 250ms;
    }

    .highcharts-venn-series .highcharts-point-hover {
      fill-opacity: 1;
      stroke: #cccccc;
    }

    /* Highstock */
    .highcharts-navigator-mask-outside {
      fill-opacity: 0;
    }

    .highcharts-navigator-mask-inside {
      fill: #6685c2;
      /* navigator.maskFill option */
      fill-opacity: 0.25;
      cursor: ew-resize;
    }

    .highcharts-navigator-outline {
      stroke: #cccccc;
      fill: none;
    }

    .highcharts-navigator-handle {
      stroke: #cccccc;
      fill: #f2f2f2;
      cursor: ew-resize;
    }

    .highcharts-navigator-series {
      fill: #335cad;
      stroke: #335cad;
    }

    .highcharts-navigator-series .highcharts-graph {
      stroke-width: 1px;
    }

    .highcharts-navigator-series .highcharts-area {
      fill-opacity: 0.05;
    }

    .highcharts-navigator-xaxis .highcharts-axis-line {
      stroke-width: 0;
    }

    .highcharts-navigator-xaxis .highcharts-grid-line {
      stroke-width: 1px;
      stroke: #e6e6e6;
    }

    .highcharts-navigator-xaxis.highcharts-axis-labels {
      fill: #999999;
    }

    .highcharts-navigator-yaxis .highcharts-grid-line {
      stroke-width: 0;
    }

    .highcharts-scrollbar-thumb {
      fill: #cccccc;
      stroke: #cccccc;
      stroke-width: 1px;
    }

    .highcharts-scrollbar-button {
      fill: #e6e6e6;
      stroke: #cccccc;
      stroke-width: 1px;
    }

    .highcharts-scrollbar-arrow {
      fill: #666666;
    }

    .highcharts-scrollbar-rifles {
      stroke: #666666;
      stroke-width: 1px;
    }

    .highcharts-scrollbar-track {
      fill: #f2f2f2;
      stroke: #f2f2f2;
      stroke-width: 1px;
    }

    .highcharts-button {
      fill: #f7f7f7;
      stroke: #cccccc;
      cursor: default;
      stroke-width: 1px;
      transition: fill 250ms;
    }

    .highcharts-button text {
      fill: #333333;
    }

    .highcharts-button-hover {
      transition: fill 0ms;
      fill: #e6e6e6;
      stroke: #cccccc;
    }

    .highcharts-button-hover text {
      fill: #333333;
    }

    .highcharts-button-pressed {
      font-weight: bold;
      fill: #e6ebf5;
      stroke: #cccccc;
    }

    .highcharts-button-pressed text {
      fill: #333333;
      font-weight: bold;
    }

    .highcharts-button-disabled text {
      fill: #333333;
    }

    .highcharts-range-selector-buttons .highcharts-button {
      stroke-width: 0px;
    }

    .highcharts-range-label rect {
      fill: none;
    }

    .highcharts-range-label text {
      fill: #666666;
    }

    .highcharts-range-input rect {
      fill: none;
    }

    .highcharts-range-input text {
      fill: #333333;
    }

    .highcharts-range-input {
      stroke-width: 1px;
      stroke: #cccccc;
    }

    input.highcharts-range-selector {
      position: absolute;
      border: 0;
      width: 1px;
      /* Chrome needs a pixel to see it */
      height: 1px;
      padding: 0;
      text-align: center;
      left: -9em;
      /* #4798 */
    }

    .highcharts-crosshair-label text {
      fill: #ffffff;
      font-size: 1.1em;
    }

    .highcharts-crosshair-label .highcharts-label-box {
      fill: black;
    }

    .highcharts-candlestick-series .highcharts-point {
      stroke: #000000;
      stroke-width: 1px;
    }

    .highcharts-candlestick-series .highcharts-point-up {
      fill: #ffffff;
    }

    .highcharts-ohlc-series .highcharts-point-hover {
      stroke-width: 3px;
    }

    .highcharts-flags-series .highcharts-point .highcharts-label-box {
      stroke: #999999;
      fill: #ffffff;
      transition: fill 250ms;
    }

    .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
      stroke: #000000;
      fill: #ccd6eb;
    }

    .highcharts-flags-series .highcharts-point text {
      fill: #000000;
      font-size: 0.9em;
      font-weight: bold;
    }

    /* Highmaps */
    .highcharts-map-series .highcharts-point {
      transition:
        fill 500ms,
        fill-opacity 500ms,
        stroke-width 250ms;
      stroke: #cccccc;
    }

    .highcharts-map-series .highcharts-point-hover {
      transition:
        fill 0ms,
        fill-opacity 0ms;
      fill-opacity: 0.5;
      stroke-width: 2px;
    }

    .highcharts-mapline-series .highcharts-point {
      fill: none;
    }

    .highcharts-heatmap-series .highcharts-point {
      stroke-width: 0;
    }

    .highcharts-map-navigation {
      font-size: 1.3em;
      font-weight: bold;
      text-align: center;
    }

    .highcharts-coloraxis {
      stroke-width: 0;
    }

    .highcharts-coloraxis-marker {
      fill: #999999;
    }

    .highcharts-null-point {
      fill: #f7f7f7;
    }

    /* 3d charts */
    .highcharts-3d-frame {
      fill: transparent;
    }

    /* Exporting module */
    .highcharts-contextbutton {
      fill: #ffffff;
      /* needed to capture hover */
      stroke: none;
      stroke-linecap: round;
    }

    .highcharts-contextbutton:hover {
      fill: #e6e6e6;
      stroke: #e6e6e6;
    }

    .highcharts-button-symbol {
      stroke: #666666;
      stroke-width: 3px;
    }

    .highcharts-menu {
      border: 1px solid #999999;
      background: #ffffff;
      padding: 5px 0;
      box-shadow: 3px 3px 10px #888;
    }

    .highcharts-menu-item {
      padding: 0.5em 1em;
      background: none;
      color: #333333;
      cursor: pointer;
      transition:
        background 250ms,
        color 250ms;
    }

    .highcharts-menu-item:hover {
      background: #335cad;
      color: #ffffff;
    }

    /* Drilldown module */
    .highcharts-drilldown-point {
      cursor: pointer;
    }

    .highcharts-drilldown-data-label text,
    text.highcharts-drilldown-data-label,
    .highcharts-drilldown-axis-label {
      cursor: pointer;
      fill: #003399;
      font-weight: bold;
      text-decoration: underline;
    }

    /* No-data module */
    .highcharts-no-data text {
      font-size: 12px;
      fill: $textColor;
    }

    /* Drag-panes module */
    .highcharts-axis-resizer {
      cursor: ns-resize;
      stroke: black;
      stroke-width: 2px;
    }

    /* Bullet type series */
    .highcharts-bullet-target {
      stroke-width: 0;
    }

    /* Lineargauge type series */
    .highcharts-lineargauge-target {
      stroke-width: 1px;
      stroke: #333333;
    }

    .highcharts-lineargauge-target-line {
      stroke-width: 1px;
      stroke: #333333;
    }

    /* Annotations module */
    .highcharts-annotation-label-box {
      stroke-width: 1px;
      stroke: #000000;
      fill: #000000;
      fill-opacity: 0.75;
    }

    .highcharts-annotation-label text {
      fill: #e6e6e6;
    }

    /* Gantt */
    .highcharts-treegrid-node-collapsed,
    .highcharts-treegrid-node-expanded {
      cursor: pointer;
    }

    .highcharts-point-connecting-path {
      fill: none;
    }

    .highcharts-grid-axis .highcharts-tick {
      stroke-width: 1px;
    }

    .highcharts-grid-axis .highcharts-axis-line {
      stroke-width: 1px;
    }

    .highcharts-tooltip-scrollable-tbody {
      display: block;
      height: 100%;
      overflow: auto;
      padding: 5px;
    }

    .highcharts-tooltip-table {
      td,
      td {
        padding: 2px 4px;
      }
    }

    .highcharts-tooltip-table-outside {
      padding-left: 5px;
      padding-right: 15px !important;
      z-index: 111;
    }
    .highcharts-tooltip-scrollable-table {
      height: 95%;
      width: 100%;
      table-layout: fixed;
    }
  }
}
