@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    $foreground: map.get($theme, foreground);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    .leaflet-container {
      a {
        color: mat.m2-get-color-from-palette($foreground, text);
        background-color: map.get($theme, card);
      }

      .leaflet-control-attribution {
        color: mat.m2-get-color-from-palette($foreground, text);
        background-color: map.get($theme, card);
      }

      .leaflet-bar a.leaflet-disabled {
        background: map.get($theme, card);
      }

      .leaflet-popup-content-wrapper,
      .leaflet-popup-tip {
        color: mat.m2-get-color-from-palette($foreground, text);
        background-color: map.get($theme, card);
      }
    }
  }
}
