@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    $foreground: map.get($theme, foreground);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    .mat-step-header {
      .mat-step-icon:not(.mat-step-icon-selected) {
        color: mat.m2-get-color-from-palette($foreground, text);
      }

      &:hover {
        background-color: transparent;
      }

      &.cdk-focused {
        background-color: transparent;
      }
    }
  }
}
