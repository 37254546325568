@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    .mat-mdc-dialog-container {
      --mdc-dialog-subhead-color: #{$primary-color};
    }

    .mat-datepicker-content {
      border-radius: map.get($theme, border-radius) !important;
    }

    .mat-mdc-menu-panel {
      border-radius: map.get($theme, border-radius) !important;
    }

    .mat-mdc-dialog-title {
      text-transform: uppercase !important;
    }
  }
}
