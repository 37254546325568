@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    mat-toolbar {
      --sds-logo-url: url('/assets/img/sds/sds_logo.svg');
    }
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $logo: map.get($theme, logo);
    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    mat-toolbar {
      background-color: map.get($theme, toolbar) !important;
      --sds-logo-url: #{url($logo)};
    }
  }

  mat-toolbar .sds-logo {
    background: var(--sds-logo-url) no-repeat;
    background-position: center;
    margin: auto;
  }
}
