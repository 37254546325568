@use '@angular/material' as mat;

mat-card {
  @include mat.elevation-transition;

  mat-card-content {
    @apply flex-auto overflow-auto;
  }

  //MDC cards does not have any native space between card header and card content
  mat-card-header + mat-card-content > :first-child {
    @apply mt-1;
  }
}

//do not remove. resolves conflict with Tailwind core https://github.com/tailwindlabs/tailwindcss/discussions/9993
.mdc-notched-outline__notch {
  border-right-style: none !important;
  border-left-style: none !important;
}

mat-expansion-panel {
  mat-expansion-panel-header {
    height: 48px !important;
  }

  &.menu-panel {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  &.menu-panel .mat-expansion-panel-body {
    padding: 0px !important;
  }
}

.mat-mdc-table {
  .mat-mdc-row.mat-no-data-row td {
    text-align: center;
  }
  .mat-mdc-header-cell {
    text-transform: uppercase;
  }
}

.mat-typography {
  @include mat.table-density(-3);
}

mat-ink-bar {
  height: 1px !important;
}

.mat-mdc-tab-body-wrapper {
  @apply h-full;
}

@for $i from 0 to 10 {
  .cdk-overlay-container {
    &:nth-of-type(#{$i}) {
      z-index: 100 + #{$i} !important;
    }
  }
}

.mat-drawer-container {
  @apply h-full;
}

.dialog-no-scroll {
  mat-dialog-container {
    @apply overflow-hidden;
  }
}

.mat-stepper-horizontal {
  @apply h-full;
}

.mat-calendar-cell-semi-selected,
.mat-calendar-body-end-range {
  .mat-calendar-body-cell-content {
    color: black !important;
  }
}

th.mat-mdc-header-cell {
  span.disabled-text {
    text-transform: none !important;
    display: block;
  }
}
