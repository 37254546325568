@use '@angular/material' as mat;
@use 'sass:map';
@use '@ag-grid-community/styles' as ag;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    @include ag.grid-styles(
      (
        theme: material,
        material-primary-color: mat.get-theme-color($theme, 'primary', 50),
        background-color: transparent,
        header-background-color: transparent,
        header-foreground-color: mat.get-theme-color($theme, 'primary', 50),
        cell-horizontal-padding: 8px,
        secondary-foreground-color: mat.get-theme-color($theme, 'primary', 50),
        checkbox-checked-color: mat.get-theme-color($theme, 'primary', 50),
        row-border-color: null
      )
    );
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $agGrid: map.get($theme, aggrid);
    $borderColor: map.get($theme, border);
    $disabledTextColor: map.get($theme, disabled-text);
    $typography: map.get($theme, typography);

    @include ag.grid-styles(
      (
        theme: material,
        material-primary-color: mat.m2-get-color-from-palette($primary-palette),
        background-color: transparent,
        foreground-color: map.get($agGrid, text-color),
        header-background-color: transparent,
        header-foreground-color: mat.m2-get-color-from-palette($primary-palette, 500),
        border-color: $borderColor,
        cell-horizontal-padding: 8px,
        row-hover-color: map.get($agGrid, row-hover-backgroud-color),
        selected-row-background-color: map.get($agGrid, selected-row-background),
        secondary-foreground-color: mat.m2-get-color-from-palette($primary-palette, 500),
        checkbox-unchecked-color: map.get($agGrid, text-color),
        checkbox-checked-color: mat.m2-get-color-from-palette($primary-palette),
        row-border-color: null
      )
    );

    .ag-popup-child {
      background-color: map.get($theme, card);
      padding-right: 1px;

      .mat-mdc-card {
        background-color: map.get($agGrid, menu-background) !important;
      }
    }

    .ag-header-cell-text {
      color: mat.m2-get-color-from-palette($primary-palette);
    }

    .ag-row {
      border-bottom: 1px solid;
      border-color: $borderColor;
      &.inactive {
        .ag-cell {
          color: $disabledTextColor;
          span {
            color: $disabledTextColor;
          }
        }
      }

      &.no-data-row {
        border: none;
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    .ag-row-hover {
      background-color: map.get($agGrid, row-hover-backgroud-color);

      .visible-on-row-hover {
        display: block;
      }
    }

    .ag-cell-inline-editing {
      height: 100% !important;
      background-color: map.get($agGrid, row-hover-backgroud-color) !important;
    }

    @media print, screen and (max-width: 1023.98px) {
      .ag-theme-material {
        .ag-header-cell {
          @include mat.m2-typography-level($typography, 'subtitle-2');
        }

        .ag-cell:not(.ellipsis),
        .ag-cell-wrapper,
        span:not(.ag-icon, .ag-cell-wrapper *) {
          @include mat.m2-typography-level($typography, 'caption');
        }
      }
    }

    @media print, screen and (min-width: 1024px) {
      .ag-theme-material {
        .ag-header-cell {
          @include mat.m2-typography-level($typography, 'subtitle-2');
        }

        .ag-cell:not(.ellipsis),
        .ag-cell-wrapper,
        span:not(.ag-icon, .ag-cell-wrapper *) {
          @include mat.m2-typography-level($typography, 'body-2');
        }
      }
    }

    .visible-cell-borders {
      .ag-header-cell,
      .ag-header-group-cell-with-group {
        border-right: 1px solid $borderColor !important;
      }

      .ag-header-group-text {
        width: 100%;
        text-align: center;
      }

      .ag-cell-label-container,
      .ag-header-cell-text,
      .ag-cell {
        padding-left: 2px;
      }

      .last-group-cell {
        border-right: 1px solid $borderColor !important;
      }
    }

    .visible-column-hover {
      .ag-column-hover {
        background-color: map.get($agGrid, row-hover-backgroud-color);
      }
    }

    .ag-tooltip {
      @include mat.elevation(4);
      color: map.get(map.get($theme, foreground), text) !important;
      background-color: map.get(map.get($theme, background), dialog) !important;
      font-size: 12px !important;
      border-radius: 4px !important;
      max-width: 250px;
      margin: 0 0 10px 0 !important;
      padding: 6px !important;
      transform: translateY(-100%);
      font:
        400 14px / 20px 'Open Sans Light',
        'Open Sans' !important;
      letter-spacing: normal !important;
    }
  }
}
