@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $accent-color: mat.m2-get-color-from-palette($accent-palette);
    $borderRadius: map.get($theme, border-radius);
    $typography: map.get($theme, typography);

    .mat-mdc-mini-fab.mat-primary:not(:disabled),
    .mat-mdc-fab.mat-primary:not(:disabled) {
      --mat-fab-small-foreground-color: #{mat.m2-get-contrast-color-from-palette($primary-palette, 500)};
      --mat-icon-color: #{mat.m2-get-contrast-color-from-palette($primary-palette, 500)};
    }

    .mat-mdc-mini-fab.mat-accent:not(:disabled),
    .mat-mdc-fab.mat-accent:not(:disabled) {
      --mat-fab-small-foreground-color: #{mat.m2-get-contrast-color-from-palette($accent-palette, 500)};
      --mat-icon-color: #{mat.m2-get-contrast-color-from-palette($accent-palette, 500)};
    }

    .mat-mdc-mini-fab[disabled] {
      --mat-icon-color: #{mat.m2-get-contrast-color-from-palette($primary-palette, 500)};
    }

    .mat-mdc-outlined-button:not(:disabled).mat-primary {
      --mdc-outlined-button-outline-color: #{$primary-color};
      background-color: $primary-color;
      color: mat.m2-get-contrast-color-from-palette($primary-palette, 500);
    }
    .mat-mdc-outlined-button:not(:disabled).mat-accent {
      --mdc-outlined-button-outline-color: #{$accent-color};
      background-color: $accent-color;
      color: mat.m2-get-contrast-color-from-palette($accent-palette, 500);
    }
  }
}
