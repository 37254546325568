@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    div {
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      ::-webkit-scrollbar-button {
        display: none;
      }

      ::-webkit-scrollbar-thumb {
        background: map.get($theme, scrollbar);
        border-radius: 50px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 50px;
      }

      ::-webkit-scrollbar-corner {
        background: transparent;
      }

      .scrollbar-thumb {
        background: map.get($theme, scrollbar);
      }
    }
  }
}
