@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    $default-font-color: beige;
    $statusColors: map.get($theme, health-status);
    /* health colors */
    @each $id, $background-color in $statusColors {
      .health-color-#{$id} {
        background-color: $background-color !important;
        color: set-font-color($background-color, $default-font-color) !important;
      }
    }
  }
}
@function set-font-color($background-color, $default-font-color) {
  $font-color: $default-font-color;
  @if color.channel($background-color, 'lightness', $space: hsl) > 40% {
    $font-color: color.invert($default-font-color);
    //$font-color: invert($background-color);
  }
  @return $font-color;
}
