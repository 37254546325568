@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $accent-color: mat.m2-get-color-from-palette($accent-palette);
    $typography: map.get($theme, typography);

    mat-card {
      background-color: map.get($theme, card) !important;
      border-radius: map.get($theme, border-radius) !important;

      .mat-mdc-card-title,
      .mat-mdc-card-title span,
      .mat-mdc-card-title label {
        color: $primary-color !important;
        @include mat.m2-typography-level($typography, 'body-1');
      }
    }
  }
}
