@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $background-color: map.get($theme, higlighted-row-column);
    $disabled-text-color: map.get($theme, higlighted-row-column-disabled-text);
    $borderColor: map.get($theme, border);
    $typography: map.get($theme, typography);

    .mat-mdc-table {
      @media only screen and (max-width: 1023.98px) {
        .mat-mdc-cell {
          //requested by Nora & Benni to have 12px on small screens
          @include mat.m2-typography-level($typography, 'caption');
        }
      }

      .mat-mdc-header-cell {
        color: $primary-color;
      }

      tfoot.mat-mdc-table-sticky,
      .mat-table-sticky.mat-mdc-table-sticky-border-elem-right {
        background: $background-color;

        .disabled-text {
          color: $disabled-text-color;
        }
      }

      .disabled-row {
        background: $background-color;
        td {
          color: $disabled-text-color;
        }
      }

      .highlighted {
        background: map.get($theme, hover);
      }
    }
  }
}
