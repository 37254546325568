@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);

    .slideshow-active-tab {
      color: $primary-color !important;
      background-color: color.adjust(map.get($theme, card), $lightness: 5%);
    }
  }
}
