@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    ngx-skeleton-loader .skeleton-loader {
      @if (map.get($map: $theme, $key: is-dark) != true) {
        background-color: color.adjust(
          $color: map.get(map.get($theme, background), background),
          $lightness: -5%,
          $space: hsl
        );
      } @else {
        background-color: color.adjust(
          $color: map.get(map.get($theme, background), background),
          $lightness: 5%,
          $space: hsl
        );
      }
    }
  }
}
