@use '@angular/material' as mat;
@use 'sass:map';

@use 'sl-dark-theme/sl-dark-theme' as dark;
@use 'sl-light-theme/sl-light-theme' as light;
@use 'sl-atomic-theme/sl-atomic-theme' as atomic;
@use 'component-theme-mixins/ag-grid' as aggrid;
@use 'component-theme-mixins/typography' as commonTypography;
@use 'component-theme-mixins/breadcrumbs';
@use 'component-theme-mixins/common-component-styles' as ccs;
@use 'component-theme-mixins/highcharts';
@use 'component-theme-mixins/sidenav';
@use 'component-theme-mixins/mat-card' as card;
@use 'component-theme-mixins/slideshow';
@use 'component-theme-mixins/skeleton-loader' as skeleton-loader;
@use 'component-theme-mixins/mat-list' as list;
@use 'component-theme-mixins/mat-toolbar' as toolbar;
@use 'component-theme-mixins/mat-form-field' as form-field;
@use 'component-theme-mixins/scrollbar';
@use 'component-theme-mixins/mat-stepper' as stepper;
@use 'component-theme-mixins/mat-dialog' as dialog;
@use 'component-theme-mixins/leaflet';
@use 'component-theme-mixins/health-status-chip' as hsc;
@use 'component-theme-mixins/button' as button;
@use 'component-theme-mixins/mat-datepicker' as datepicker;
@use 'component-theme-mixins/mat-checkbox' as checkbox;
@use 'component-theme-mixins/ngx-file-drop' as ngxFileDrop;
@use 'component-theme-mixins/mat-table' as matTable;
@use 'component-theme-mixins/material-icon-font' as matIconFont;
@use 'component-theme-mixins/mat-icon' as matIcon;
@use 'component-theme-mixins/mat-snackbar' as snackbar;
@use 'component-theme-mixins/mat-chip' as chip;
@use 'component-theme-mixins/userlane' as userlane;
@use 'component-theme-mixins/gridster-item' as gridster-item;
@use 'component-theme-mixins/analysis-overview-fleet-search' as analysis-overview-fleet-search;
@use '@ag-grid-community/styles' as ag;
mat.$theme-ignore-duplication-warnings: true;
@include ag.grid-styles(
  (
    theme: material
  )
);
@include mat.elevation-classes();
@include mat.app-background();

.dark-theme {
  --mat-sys-background: black;
  @include mat.elevation-classes();
  @include mat.autocomplete-theme(dark.$theme);
  @include mat.button-theme(dark.$theme);
  @include mat.icon-button-theme(dark.$theme);
  @include mat.fab-theme(dark.$theme);
  @include mat.bottom-sheet-theme(dark.$theme);
  @include mat.card-theme(dark.$theme);
  @include mat.checkbox-theme(dark.$theme);
  @include mat.chips-theme(dark.$theme);
  @include mat.datepicker-theme(dark.$theme);
  @include mat.dialog-theme(dark.$theme);
  @include mat.divider-theme(dark.$theme);
  @include mat.expansion-theme(dark.$theme);
  @include mat.form-field-theme(dark.$theme);
  @include mat.grid-list-theme(dark.$theme);
  @include mat.icon-theme(dark.$theme);
  @include mat.input-theme(dark.$theme);
  @include mat.list-theme(dark.$theme);
  @include mat.menu-theme(dark.$theme);
  @include mat.progress-bar-theme(dark.$theme);
  @include mat.pseudo-checkbox-theme(dark.$theme);
  @include mat.progress-spinner-theme(dark.$theme);
  @include mat.radio-theme(dark.$theme);
  @include mat.select-theme(dark.$theme);
  @include mat.slide-toggle-theme(dark.$theme);
  @include mat.slider-theme(dark.$theme);
  @include mat.snack-bar-theme(dark.$theme);
  @include mat.table-theme(dark.$theme);
  @include mat.tabs-theme(dark.$theme);
  @include mat.tree-theme(dark.$theme);
  @include mat.sidenav-theme(dark.$theme);
  @include mat.stepper-theme(dark.$theme);
  @include mat.toolbar-theme(dark.$theme);
  @include mat.tooltip-theme(dark.$theme);
  @include mat.button-toggle-theme(dark.$theme);

  @include aggrid.theme(dark.$theme);
  @include commonTypography.theme(dark.$theme);
  @include breadcrumbs.theme(dark.$theme);
  @include ccs.theme(dark.$theme);
  @include highcharts.theme(dark.$theme);
  @include sidenav.theme(dark.$theme);
  @include card.theme(dark.$theme);
  @include slideshow.theme(dark.$theme);
  @include list.theme(dark.$theme);
  @include skeleton-loader.theme(dark.$theme);
  @include toolbar.theme(dark.$theme);
  @include scrollbar.theme(dark.$theme);
  @include dialog.theme(dark.$theme);
  @include leaflet.theme(dark.$theme);
  @include stepper.theme(dark.$theme);
  @include hsc.theme(dark.$theme);
  @include button.theme(dark.$theme);
  @include datepicker.theme(dark.$theme);
  @include checkbox.theme(dark.$theme);
  @include ngxFileDrop.theme(dark.$theme);
  @include form-field.theme(dark.$theme);
  @include matTable.theme(dark.$theme);
  @include matIconFont.theme(dark.$theme);
  @include matIcon.theme(dark.$theme);
  @include snackbar.theme(dark.$theme);
  @include chip.theme(dark.$theme);
  @include userlane.theme(dark.$theme);
  @include gridster-item.theme(dark.$theme);
  @include analysis-overview-fleet-search.theme(dark.$theme);
}

.light-theme {
  --mat-sys-background: white;
  @include mat.elevation-classes();
  @include mat.autocomplete-theme(light.$theme);
  @include mat.button-theme(light.$theme);
  @include mat.icon-button-theme(light.$theme);
  @include mat.fab-theme(light.$theme);
  @include mat.bottom-sheet-theme(light.$theme);
  @include mat.card-theme(light.$theme);
  @include mat.checkbox-theme(light.$theme);
  @include mat.chips-theme(light.$theme);
  @include mat.datepicker-theme(light.$theme);
  @include mat.dialog-theme(light.$theme);
  @include mat.divider-theme(light.$theme);
  @include mat.expansion-theme(light.$theme);
  @include mat.form-field-theme(light.$theme);
  @include mat.grid-list-theme(light.$theme);
  @include mat.icon-theme(light.$theme);
  @include mat.input-theme(light.$theme);
  @include mat.list-theme(light.$theme);
  @include mat.menu-theme(light.$theme);
  @include mat.progress-bar-theme(light.$theme);
  @include mat.progress-spinner-theme(light.$theme);
  @include mat.pseudo-checkbox-theme(light.$theme);
  @include mat.radio-theme(light.$theme);
  @include mat.select-theme(light.$theme);
  @include mat.slide-toggle-theme(light.$theme);
  @include mat.slider-theme(light.$theme);
  @include mat.snack-bar-theme(light.$theme);
  @include mat.table-theme(light.$theme);
  @include mat.tabs-theme(light.$theme);
  @include mat.tree-theme(light.$theme);
  @include mat.sidenav-theme(light.$theme);
  @include mat.stepper-theme(light.$theme);
  @include mat.toolbar-theme(light.$theme);
  @include mat.tooltip-theme(light.$theme);
  @include mat.button-toggle-theme(light.$theme);

  @include aggrid.theme(light.$theme);
  @include commonTypography.theme(light.$theme);
  @include breadcrumbs.theme(light.$theme);
  @include ccs.theme(light.$theme);
  @include highcharts.theme(light.$theme);
  @include sidenav.theme(light.$theme);
  @include card.theme(light.$theme);
  @include slideshow.theme(light.$theme);
  @include skeleton-loader.theme(light.$theme);
  @include list.theme(light.$theme);
  @include toolbar.theme(light.$theme);
  @include scrollbar.theme(light.$theme);
  @include dialog.theme(light.$theme);
  @include leaflet.theme(light.$theme);
  @include stepper.theme(light.$theme);
  @include hsc.theme(light.$theme);
  @include button.theme(light.$theme);
  @include datepicker.theme(light.$theme);
  @include checkbox.theme(light.$theme);
  @include ngxFileDrop.theme(light.$theme);
  @include form-field.theme(light.$theme);
  @include matTable.theme(light.$theme);
  @include matIconFont.theme(light.$theme);
  @include matIcon.theme(light.$theme);
  @include snackbar.theme(light.$theme);
  @include chip.theme(light.$theme);
  @include userlane.theme(light.$theme);
  @include gridster-item.theme(light.$theme);
  @include analysis-overview-fleet-search.theme(light.$theme);
}

.atomic-theme {
  --mat-sys-background: white;
  @include mat.elevation-classes();
  @include mat.color-variants-backwards-compatibility(atomic.$theme);
  @include mat.autocomplete-theme(atomic.$theme);
  @include mat.button-theme(atomic.$theme);
  @include mat.icon-button-theme(atomic.$theme);
  @include mat.fab-theme(atomic.$theme);
  @include mat.bottom-sheet-theme(atomic.$theme);
  @include mat.card-theme(atomic.$theme);
  @include mat.checkbox-theme(atomic.$theme);
  @include mat.chips-theme(atomic.$theme);
  @include mat.datepicker-theme(atomic.$theme);
  @include mat.dialog-theme(atomic.$theme);
  @include mat.divider-theme(atomic.$theme);
  @include mat.expansion-theme(atomic.$theme);
  @include mat.form-field-theme(atomic.$theme);
  @include mat.grid-list-theme(atomic.$theme);
  @include mat.icon-theme(atomic.$theme);
  @include mat.input-theme(atomic.$theme);
  @include mat.list-theme(atomic.$theme);
  @include mat.menu-theme(atomic.$theme);
  @include mat.progress-bar-theme(atomic.$theme);
  @include mat.progress-spinner-theme(atomic.$theme);
  @include mat.pseudo-checkbox-theme(atomic.$theme);
  @include mat.radio-theme(atomic.$theme);
  @include mat.select-theme(atomic.$theme);
  @include mat.slide-toggle-theme(atomic.$theme);
  @include mat.slider-theme(atomic.$theme);
  @include mat.snack-bar-theme(atomic.$theme);
  @include mat.table-theme(atomic.$theme);
  @include mat.tabs-theme(atomic.$theme);
  @include mat.tree-theme(atomic.$theme);
  @include mat.sidenav-theme(atomic.$theme);
  @include mat.stepper-theme(atomic.$theme);
  @include mat.toolbar-theme(atomic.$theme);
  @include mat.tooltip-theme(atomic.$theme);
  @include mat.button-toggle-theme(atomic.$theme);

  @include aggrid.theme(atomic.$theme);
  @include breadcrumbs.theme(atomic.$theme);
  @include ccs.theme(atomic.$theme);
  @include highcharts.theme(atomic.$theme);
  @include sidenav.theme(atomic.$theme);
  @include card.theme(atomic.$theme);
  @include slideshow.theme(atomic.$theme);
  @include list.theme(atomic.$theme);
  @include skeleton-loader.theme(atomic.$theme);
  @include toolbar.theme(atomic.$theme);
  @include scrollbar.theme(atomic.$theme);
  @include dialog.theme(atomic.$theme);
  @include leaflet.theme(atomic.$theme);
  @include stepper.theme(atomic.$theme);
  @include hsc.theme(atomic.$theme);
  @include button.theme(atomic.$theme);
  @include datepicker.theme(atomic.$theme);
  @include checkbox.theme(atomic.$theme);
  @include ngxFileDrop.theme(atomic.$theme);
  @include form-field.theme(atomic.$theme);
  @include matTable.theme(atomic.$theme);
  @include matIconFont.theme(atomic.$theme);
  @include matIcon.theme(atomic.$theme);
  @include snackbar.theme(atomic.$theme);
  @include chip.theme(atomic.$theme);
  @include userlane.theme(atomic.$theme);
  @include gridster-item.theme(atomic.$theme);
  @include analysis-overview-fleet-search.theme(atomic.$theme);
}
