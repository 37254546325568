@use './sl-themes';
@use 'gridster';
@use 'highcharts-series-colors';
@use './non-theme-styles/material.scss';
@use './non-theme-styles/ag-grid.scss';
@tailwind base;
@tailwind utilities;

/* open-sans-300 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans Light';
  font-weight: 300;
  src: url('..//assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-ext_latin/open-sans-v16-latin-ext_latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Nunito Sans';
  src:
    local('Nunito Sans'),
    url('../assets/fonts/nunito-sans/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'alarm-clock';
  src: url('../assets/fonts/alarm_clock.ttf') format('truetype');
}

html,
body {
  height: 100%;
  margin: 0;
}

input[type='time'] {
  position: relative;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}

//hide the number input up/down arrows to save space
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  display: none;
}
