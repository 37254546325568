@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $typography: map.get($theme, typography);
  $theme-name: map.get($theme, $key: name);

  h1,
  h2,
  h3,
  h4 {
    color: mat.m2-get-color-from-palette($primary-palette, 700);
    display: inline-block;
  }

  h1 {
    @include mat.m2-typography-level($typography, 'headline-5');
  }
  h2 {
    @include mat.m2-typography-level($typography, 'headline-6');
  }
  h3 {
    @include mat.m2-typography-level($typography, 'subtitle-1');
    text-transform: uppercase;
  }
  h4 {
    @include mat.m2-typography-level($typography, 'subtitle-2');
    text-transform: uppercase;
  }
  a,
  p,
  span:not(.mat-mdc-icon, .material-icons, .ag-icon),
  label,
  text,
  li,
  pre,
  .ag-theme-material {
    @include mat.m2-typography-level($typography, 'body-1');
  }
}
