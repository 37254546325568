// any change in colors should be verified against custom series color in highcharts-status-color.enum.ts
$seriesColors: (
  0: #ffe400,
  1: #0b5394,
  2: #cc0000,
  3: #6aa84f,
  4: #783f04,
  5: #eae166,
  6: #674ea7,
  7: #ff5699,
  8: #f1c232,
  9: #38761d,
  10: #b45f06,
  11: #3d85c6,
  12: #e69138,
  13: #3f51b5,
  14: #45818e,
  15: #ff5733,
  16: #a64d79,
  17: #bf9000,
  18: #5a8544,
  19: #c52b77,
  20: #e2e53e,
  21: #3e77e5,
  22: #e53e3e,
  23: #ceb638,
  24: #33ff4b,
  25: #e23ee5,
  26: #dd7615,
  27: #b9bb04,
  28: #4184a9,
  29: #db3b18,
  30: #549253,
  31: #a54083,
  32: #bb6c6c,
  33: #ffec4d,
  34: #5487b4,
  35: #db4d4d,
  36: #97c284,
  37: #a1794f,
  38: #f0ea94,
  39: #9583c1,
  40: #ff89b8,
  41: #f5d470,
  42: #749f61,
  43: #cb8f51,
  44: #77aad7,
  45: #eeb274,
  46: #7985cb,
  47: #7da7b0,
  48: #ff8970,
  49: #c182a1,
  50: #8caa7c,
  51: #d2b14d,
  52: #d66ba0,
  53: #ebed78,
  54: #78a0ed,
  55: #ed7878,
  56: #ddcc74,
  57: #70ff81,
  58: #eb78ed,
  59: #e79f5b,
  60: #cecf4f,
  61: #7aa9c3,
  62: #e6765d,
  63: #87b387,
  64: #c079a8,
  65: #cf9898,
  66: #ffe826,
  67: #306da4,
  68: #d42626,
  69: #80b569,
  70: #8c5c2a,
  71: #ede67d,
  72: #7e69b4,
  73: #ff6fa8,
  74: #f3cb51,
  75: #568b3f,
  76: #bf772b,
  77: #5a97cf,
  78: #eaa256,
  79: #5c6bc0,
  80: #61949f,
  81: #ff7052,
  82: #b3688d,
  83: #739760,
  84: #c9a126,
  85: #ce4b8b,
  86: #e6e95b,
  87: #5b8be9,
  88: #e95b5b,
  89: #d5c156,
  90: #52ff66,
  91: #e65be9,
  92: #e28b38,
  93: #c4c52a,
  94: #5e96b6,
  95: #e0583b,
  96: #6ea26d,
  97: #b35d96,
  98: #c58282,
  99: #ffe100,
  100: #0a4c8c,
  101: #c70000,
  102: #62a048,
  103: #703903,
  104: #e7dd5e,
  105: #5f479f,
  106: #ff4f91,
  107: #efbc2d,
  108: #326e1a,
  109: #ad5705,
  110: #377dc0,
  111: #e38932,
  112: #394aae,
  113: #3e7986,
  114: #ff4f2e,
  115: #9e4671,
  116: #527d3e,
  117: #b98800,
  118: #bf266f,
  119: #dfe238,
  120: #386fe2,
  121: #e23838,
  122: #c9af32,
  123: #2eff44,
  124: #df38e2,
  125: #d96e12,
  126: #b2b503,
  127: #3b7ca2,
  128: #d73515,
  129: #4d8a4c,
  130: #9d3a7b,
  131: #b56464,
  132: #ffdd00,
  133: #084281,
  134: #c00000,
  135: #57973f,
  136: #653103,
  137: #e4d953,
  138: #543d96,
  139: #ff4586,
  140: #edb426,
  141: #2b6315,
  142: #a44d04,
  143: #2f72b9,
  144: #df7e2b,
  145: #3140a5,
  146: #366e7b,
  147: #ff4627,
  148: #953d66,
  149: #487235,
  150: #b17d00,
  151: #b82064,
  152: #dade30,
  153: #3064de,
  154: #de3030,
  155: #c2a62b,
  156: #27ff3b,
  157: #da30de,
  158: #d4630f,
  159: #aaac03,
  160: #327198,
  161: #d22d11,
  162: #437f42,
  163: #933270,
  164: #ac5959,
  165: #ffd900,
  166: #063977,
  167: #b90000,
  168: #4d8d36,
  169: #5b2902,
  170: #e1d549,
  171: #4a358c,
  172: #ff3c7c,
  173: #ebac1f,
  174: #245911,
  175: #9c4303,
  176: #2768b1,
  177: #db7424,
  178: #29379d,
  179: #2e6471,
  180: #ff3c20,
  181: #8b345c,
  182: #3f682d,
  183: #a97300,
  184: #b01a5a,
  185: #d6da28,
  186: #285ada,
  187: #da2828,
  188: #bc9e24,
  189: #20ff33,
  190: #d628da,
  191: #cf590c,
  192: #a2a402,
  193: #2a678f,
  194: #cd260e,
  195: #3a7539,
  196: #8a2a66,
  197: #a44f4f,
  198: #ffd100,
  199: #032965,
  200: #ad0000,
  201: #3c7d26,
  202: #481b01,
  203: #dbcd38,
  204: #39257c,
  205: #ff2b6b,
  206: #e79f13,
  207: #17460a,
  208: #8c3202,
  209: #1a55a4,
  210: #d56217,
  211: #1b278d,
  212: #1f515f,
  213: #ff2c14,
  214: #7b2549,
  215: #2e551f,
  216: #9b6100,
  217: #a31047,
  218: #cfd31b,
  219: #1b47d3,
  220: #d31b1b,
  221: #b08e17,
  222: #14ff23,
  223: #cf1bd3,
  224: #c74606,
  225: #939601,
  226: #1c547e,
  227: #c41908,
  228: #296329,
  229: #791c53,
  230: #963d3d,
  231: #898989,
  232: #666,
  233: #625151,
  234: #00cc00,
  235: #ffe400,
  236: #ff4d4d,
  237: #0045d2,
  238: #9556bd,
  239: #1aadce,
  240: #e1ac07
);

$chargeColors: (
  'charge-0': #98fb98,
  'charge-1': #00ff00,
  'charge-2': #228b22,
  'charge-3': #8fbc8f,
  'charge-4': #006400,
  'charge-5': #3c7e3c,
  'charge-6': #2e8b57,
  'charge-7': #32cd32,
  'charge-8': #008000,
  'charge-9': #3cb371
);

$dischargeColors: (
  'discharge-0': #0045d2,
  'discharge-1': #3e81d8,
  'discharge-2': #00bfff,
  'discharge-3': #000080,
  'discharge-4': #1e90ff,
  'discharge-5': #0000ff,
  'discharge-6': #6495ed,
  'discharge-7': #4682b4,
  'discharge-8': #4169e1,
  'discharge-9': #87cefa
);

$voltageColors: (
  'voltage-0': #f28f43,
  'voltage-1': #d4a143,
  'voltage-2': #eec4ae,
  'voltage-3': #e09200,
  'voltage-4': #ffbb3d,
  'voltage-5': #db7832,
  'voltage-6': #ec977a,
  'voltage-7': #ffb833,
  'voltage-8': #af755e,
  'voltage-9': #ffba00
);

$stateOfChargeColors: (
  'state-of-charge-0': #ff0038,
  'state-of-charge-1': #ce2029,
  'state-of-charge-2': #860111,
  'state-of-charge-3': #ff2424,
  'state-of-charge-4': #ba160c,
  'state-of-charge-5': #ed1c24,
  'state-of-charge-6': #da2c43,
  'state-of-charge-7': #ad0000,
  'state-of-charge-8': #e51a4c,
  'state-of-charge-9': #ff0800
);

$colorArray: ($seriesColors, $chargeColors, $dischargeColors, $voltageColors, $stateOfChargeColors);

:root {
  --highcharts-background-color: transparent !important;
  @each $colors in $colorArray {
    @each $id, $color in $colors {
      & {
        --highcharts-color-#{$id}: #{$color} !important;
      }
      .highcharts-color-#{$id} {
        fill: var(--highcharts-color-#{$id}) !important;
        stroke: var(--highcharts-color-#{$id}) !important;
      }
    }
  }
}
