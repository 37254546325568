gridster {
  .gridster-item-inner {
    height: 100%;
  }

  &-item:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
  }

  @media (hover: hover) {
    gridster-item .visible-on-gridster-item-hover {
      visibility: hidden;
    }

    gridster-item:hover .visible-on-gridster-item-hover {
      visibility: visible;
    }
  }

  @media (hover: none) {
    gridster-item .visible-on-gridster-item-hover {
      visibility: visible;
    }
  }
}
