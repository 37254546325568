@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $warn-palette: map.get($color-config, 'warn');

    $warn-color: mat.m2-get-color-from-palette($warn-palette);
    $typography: map.get($theme, typography);

    .mat-mdc-form-field.mat-focused {
      .mat-mdc-form-field-required-marker {
        color: $warn-color !important;
      }
    }
  }
}
