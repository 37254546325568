@use '@angular/material' as mat;

@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    $typography: map.get($theme, typography);
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    .usln-p-assistant-chapters-page,
    .usln-p-assistant-bubble,
    .usln-p-assistant-head,
    .usln-p-assistant-nav-bar,
    .fkIzDm {
      background-color: map.get($theme, card) white;
      p {
        color: black;
      }
    }
  }
  .usln-p-assistant-head-texts {
    pointer-events: none !important;
  }

  span.userlane-svg-icon {
    font-size: 24px !important;
    color: #666 !important;
  }

  span.usln-icon {
    color: white !important;
  }

  span.userlane-svg-icon.usln-list-item-icon {
    color: white !important;
  }

  .usln-index {
    background-color: #666 !important;
    span {
      color: white !important;
    }
  }
}
