@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
  } @else {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    $foreground: map.get($theme, foreground);
    $background: map.get($theme, background);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $primary-color: mat.m2-get-color-from-palette($primary-palette);
    $borderColor: map.get($theme, border);
    $border-tag-color: map.get($theme, border);
    $textColor: mat.m2-get-color-from-palette($foreground, text);
    $disabledTextColor: map.get($theme, disabled-text);
    $primary-contrast: mat.m2-get-color-from-palette($primary-palette, default-contrast);
    $borderRadius: map.get($theme, border-radius);
    $typography: map.get($theme, typography);

    //do not remove - targeting body element
    &.mat-typography {
      @include mat.m2-typography-level($typography, 'body-2');
      color: map.get($foreground, text);
    }

    [class^='border'],
    [class*='border'] {
      border-color: $borderColor;
    }

    .light-border {
      border-color: map.get($theme, border) !important;
    }

    .border-radius {
      border-radius: $borderRadius;
    }

    .light-background {
      background: map.get($theme, light-background);
    }

    .background-color {
      background: map.get($theme, card);
    }

    .titleStyle {
      color: $primary-color;
      text-transform: uppercase;
    }

    .country-selector-code {
      color: $textColor !important;
    }

    .country-list-button {
      color: $textColor !important;
    }

    .text-color {
      color: $textColor !important;
    }

    .disabled-text {
      color: $disabledTextColor !important;
    }

    .clickable {
      cursor: pointer;
    }

    .dragable:hover {
      cursor: move;
      background-color: $borderColor;
    }

    .tooltip-above {
      background: map.get($theme, light-background);
      margin-bottom: 2px;
    }

    .mat-mdc-menu-item {
      &:hover {
        background: map.get($theme, light-background);
      }

      &:disabled {
        color: $disabledTextColor;
        cursor: not-allowed;

        .mat-icon-no-color {
          color: $disabledTextColor;
        }
      }
    }

    .sds-tag {
      border-color: $border-tag-color !important;
      background-color: transparent;
      color: $textColor !important;
      padding: 2px 5px;
      border: 1px solid;
      margin-right: 5px;
      font-weight: 500 !important;
      font-size: 10px;
      text-transform: uppercase;
      color: mat.m2-get-color-from-palette($foreground, text);
      cursor: default;

      &.active {
        background-color: $primary-color;
        color: $primary-contrast !important;
      }
    }
    .mat-list-single-selected-option,
    .mat-stroked-button-toggle-checked,
    .ag-row-selected {
      color: $primary-color !important;
      background: map.get($theme, light-background) !important;
    }

    .leaflet-container {
      background: none !important;
    }

    span.matSuffix.disabled {
      color: $disabledTextColor;
    }

    .chart-menu-tab-group {
      .mat-mdc-tab-header {
        border-radius: $borderRadius $borderRadius 0 0;
        background: map.get($theme, card);
      }
    }

    .selectedRow {
      background-color: map.get($theme, selected-row);
    }
  }
}
